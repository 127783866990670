<template>
  <b-modal 
    id="beneficiary-modal" 
    size="lg"
    centered
    :title="beneficiary ? 'Editar favorecido' : 'Cadastrar favorecido'"
    >
    <section >
      <div class="row">
        <div class="col-8">
          <b-form-group class="form-group">
            <label for="name">Nome / Razão social</label>
            <b-form-input
              autocomplete="off"
              id="name"
              v-model="form.name"
              placeholder="Descrever nome ou razão social do favorecido"
              debounce="500"
              :disabled="this.form.type === 'CLINIC_PROFESSIONAL'"
            />
            <div v-if="validated && !form.name" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group class="form-group">
            <label for="num">
              CPF / CNPJ
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              autocomplete="off"
              id="num"
              v-model="form.number"
              placeholder="Descrever"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              debounce="500"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group class="form-group">
            <label for="phone">
              Telefone
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              id="phone"
              autocomplete="off"
              v-model="form.phone"
              placeholder="Descrever"
              v-mask="['(##) ####-####', '(##) ####-####']"
              debounce="500"
            />
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group class="form-group">
            <label for="email">
              E-mail
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              id="email"
              autocomplete="off"
              v-model="form.email"
              placeholder="Descrever"
              :disabled="this.form.type === 'CLINIC_PROFESSIONAL'"
              debounce="500"
            />
          </b-form-group>
        </div>  
      </div>

      <hr />

      <div class="row">
        <div class="col-4">
          <b-form-group class="form-group">
            <label for="cep">
              CEP
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              id="cep"
              autocomplete="off"
              @keyup="searchCEP"
              v-mask="'#####-###'"
              v-model="form.address.zipCode"
              placeholder="Descrever"
            />
            <div v-if="validated && form.address.zipCode && form.address.zipCode.length !== 9" class="custom-invalid-feedback">
              CEP incompleto
            </div>
          </b-form-group>
        </div>

        <div class="col-5">
          <b-form-group class="form-group">
            <label for="address">
              Logradouro
            </label>
            <b-form-input
              id="address"
              autocomplete="off"
              v-model="form.address.address"
              :disabled="!form.address.zipCode"
              debounce="500"
              readonly
            />
            <div v-if="validated && !form.address.address && form.address.zipCode" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group class="form-group">
            <label for="number">
              Número
            </label>
            <b-form-input
              id="number"
              autocomplete="off"
              ref="addressNumber"
              placeholder="01"
              v-model="form.address.number"
              :disabled="!form.address.zipCode"
              debounce="500"
            />
            <div v-if="validated && !form.address.number && form.address.zipCode" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group class="form-group">
            <label for="neighborhood">
              Bairro
            </label>
            <b-form-input
              id="neighborhood"
              autocomplete="off"
              v-model="form.address.neighborhood"
              :disabled="!form.address.zipCode"
              debounce="500"
              readonly
            />
            <div v-if="validated && !form.address.neighborhood && form.address.zipCode" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group class="form-group">
            <label for="complement">
              Complemento
            </label>
            <b-form-input
              id="complement"
              autocomplete="off"
              v-model="form.address.complement"
              :disabled="!form.address.zipCode"
              placeholder="Descrever"
              debounce="500"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group id="field_category">
            <label for="state">
              Estado
            </label>
            <multiselect
              id="neighborhood"
              autocomplete="off"
              v-model="form.address.state"
              debounce="500"
              :options="stateOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              :allowEmpty="false"
              class="with-border"
              placeholder="Selecionar"
              :disabled="!form.address.zipCode"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhuma opção
              </template>
              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div v-if="validated && !form.address.state && form.address.zipCode" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group class="form-group">
            <label for="city">
              Cidade
            </label>
            <b-form-input
              id="city"
              autocomplete="off"
              v-model="form.address.city"
              :disabled="!form.address.zipCode"
              debounce="500"
              readonly
            />
            <div v-if="validated && !form.address.city && form.address.zipCode" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group class="form-group">
            <label for="observations">
              Observações
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              id="observations"
              autocomplete="off"
              v-model="form.observations"
              placeholder="Descrever observação"
              debounce="500"
            />
          </b-form-group>
        </div>
      </div>

    </section>
    <template #modal-footer>
        <b-button 
        variant="primary"
        @click="save"
        >
          {{ form.id ? 'Atualizar' : 'Salvar'}}
        </b-button>
    </template>
  </b-modal>
</template>

<script>
import * as cep from 'cep-promise'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  props: {
    beneficiary: Object,
  },
  data(){
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: this.getDefaultForm(),
      validated: false,

      stateOptions: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
      ],
    }
  },
  methods: {
    getDefaultForm(){
      return {
        id: null,
        person: null,
        number: null,
        type: 'EXTERNAL',
        name: null,
        phone: null,
        email: null,
        observations: null,
        address: {
          id: null,
          zipCode: null,
          state: null,
          city: null,
          complement: null,
          number: null,
          address: null,
          neighborhood: null,
          country: 'Brazil'
        }
      }
    },
    isValidForm(){
      const requiredFields = ['name']
      const addressRequiredFields = ['address', 'number', 'neighborhood', 'state', 'city']
      let isValidForm = true
      requiredFields.map( key => {
        if(!this.form[key])
        isValidForm = false
      })

      if( this.form.address.zipCode ){
        addressRequiredFields.map( key => {
          if( !this.form.address[key] ) 
          isValidForm =  false
        })
      }

      this.validated = true
      return isValidForm
    },

    async save(){
      if( !this.isValidForm() ) {
        this.$toast.error('Existem campos de preenchimento obrigatório!')
        return
      }
      const isLoading = this.$loading.show()
      try {
        this.form.clinic_id = this.clinic.id
          
        if( !this.form.id ){
          await this.api.createBeneficiary(this.form)
          this.$toast.success('Favorecido criado com sucesso!')
        }
        else {
          await this.api.updateBeneficiary(this.form.id, this.form)
          this.$toast.success('Favorecido alterado com sucesso!')
        }
      } catch (error) {
				this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.validated = false
        this.$bvModal.hide('beneficiary-modal');
        this.$emit('onUpdate')
        this.form = this.getDefaultForm()
      }
    },
    searchCEP() {
      if (this.form.address.zipCode && this.form.address.zipCode.length === 9) {
        cep(this.form.address.zipCode)
          .then(res => {
            this.form.address.address = res.street
            this.form.address.neighborhood = res.neighborhood
            this.form.address.city = res.city
            this.form.address.state = res.state

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            this.$toast.error(err.message)
          })
      }
    },
  },
  watch: {  
    beneficiary: function(value) {
      if( value ){
        this.form = value
      }
      else {
        this.form = this.getDefaultForm()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-group input:read-only {
  background: var(--neutral-100);
}
</style>